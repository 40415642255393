<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { vClickOutside } from '~/directives/ClickOutside'

import { useLanguage } from '~/composables/useLanguage'

import { useUserStore } from '~/stores/user'

import { availableLanguages } from '~/types/language'

import type { Language } from '~/types/language'

type Props = {
  dropdownPosition?: 'bottom' | 'top'
  localeVerbosity?: 'full-length' | 'short'
}
type Slots = {
  'left-text'(): any
}

withDefaults(defineProps<Props>(), {
  dropdownPosition: 'top',
  localeVerbosity: 'full-length',
})
defineSlots<Slots>()

const isMenuVisible = ref(false)

const { email: USER_EMAIL } = storeToRefs(useUserStore())

const { getCurrentLanguage, setCurrentLanguage } = useLanguage()

const currentLanguage = computed(() => getCurrentLanguage())
const sortedAvailableLanguageChoices = computed(() =>
  availableLanguages.filter((e) => e !== currentLanguage.value).toSorted(),
)

async function switchToLanguage(language: Language) {
  closeMenu()
  return navigateTo(await setCurrentLanguage(language, USER_EMAIL.value))
}

function closeMenu() {
  isMenuVisible.value = false
}
</script>

<template>
  <div v-click-outside="closeMenu" class="tw-relative tw-w-fit">
    <transition name="fade" mode="out-in">
      <div
        v-if="isMenuVisible"
        class="tw-pointer tw-absolute tw-right-0 tw-flex tw-w-24 tw-flex-col tw-bg-white tw-p-2"
        :class="{
          'tw-bottom-10': dropdownPosition === 'top',
          'tw-top-10': dropdownPosition === 'bottom',
        }"
      >
        <VText
          v-for="language in sortedAvailableLanguageChoices"
          :key="language"
          cfg="sans/14/medium"
          color="black"
          class="tw-ellipsis tw-flex tw-h-10 tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-px-5 tw-transition-colors hover:tw-bg-gray-200"
          @click="switchToLanguage(language)"
        >
          {{
            $t(
              `${localeVerbosity === 'full-length' ? 'locales' : 'localesShort'}.${language}`,
            )
          }}
        </VText>
      </div>
    </transition>

    <div
      class="tw-ellipsis tw-flex tw-w-full tw-justify-center tw-gap-1 sm:tw-w-auto sm:tw-justify-end"
    >
      <span
        ><slot name="left-text">{{
          $t('components.ui.langSwitcher.lang')
        }}</slot></span
      >
      <div class="tw-flex" @click="isMenuVisible = !isMenuVisible">
        <b class="tw-mr-1 tw-cursor-pointer tw-select-none">{{
          $t(
            `${localeVerbosity === 'full-length' ? 'locales' : 'localesShort'}.${currentLanguage}`,
          )
        }}</b>
        <i
          class="fas fa-chevron-down tw-origin-center tw-self-center tw-transition-transform"
          :class="{ 'tw-rotate-180': isMenuVisible }"
        />
      </div>
    </div>
  </div>
</template>
